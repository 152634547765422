<template>
    <div class="linkedin-resume-detail">
        <div class="linkedin-resume-detail-content">
            <base-info
                :candidateDetailData="candidateDetailData"
            ></base-info>
            <!-- <div class="linkedin-resume-detail-tab"><span>主页</span></div> -->
            <candidate-resume-detail 
                :is-linkedin="true"
                ref="candidateResumeDetail" 
                class="linkedin-resume-detail-card" 
                :candidate-id="candidateId" 
                :is-firm="candidateDetailData.isFirm" 
                :resume-id="candidateDetailData.tbdResumeId"
            ></candidate-resume-detail>
        </div>

        <footer-container></footer-container>
    </div>
</template>

<script>
import FooterContainer from '@/hr/component/footer.vue';
import { candidate as candidateUrl } from '#/js/config/api.json';
import CandidateResumeDetail from '#/views/candidate-detail/layout/candidateResumeDetail.vue';
import BaseInfo from './base-info.vue';
export default {
    components: {
        FooterContainer,
        CandidateResumeDetail,
        BaseInfo
    },
    data() {
        return {
            candidateId: '',
            candidateDetailData: {}
        }
    },
    mounted() {
        this.candidateId = this.$route.params.resumeId;
        if(!this.candidateId) {
            shortTips('简历不存在！');
            return false;
        }
        this.getCandidateDetail();
    },
    methods: {
        routertitle(){
            let firmName = this.$store.state.user.userInfo.firmShortName;
            let title = this.candidateDetailData.realName + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
            document.title = title;
        },
        getCandidateDetail() {
            _request({
                url: candidateUrl.linkedin_candidate_detail.replace('%p', this.candidateId),
                method: 'GET'
            }).then(res => {
                this.candidateDetailData = res || {};
                this.routertitle();
                this.$refs.candidateResumeDetail.update(this.candidateDetailData, this.candidateDetailData);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.linkedin-resume-detail {
    height: 100%;
    overflow-y: auto;
    padding: 20px;

    &-content {
        padding-bottom: 56px;

        .linkedin-resume-detail-card {
            min-height: calc(100vh - 315px);
        }
    }

    &-tab{
        height: 50px;
        line-height: 50px;
        width: 800px;
        margin: 0 auto 30px;

        span {
            display: inline-block;
            width: 115px;
            height: 100%;
            text-align: center;
            font-size: 16px;
            color: #333;
            border-bottom: 2px solid $primary;
        }
    }

    &-card {
        background-color: #ffffff;
        border-radius: 8px;

        /deep/ .candidate-resume-detail {
            margin-left: 0;
            margin-right: 0;
        }
    }
} 
</style>