var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-base-info" }, [
    _c("div", { staticClass: "col-16" }, [
      _c("h3", { staticClass: "resume-headline" }, [
        _c("div", { staticClass: "resume-base-info" }, [
          _c("span", { staticClass: "resume-name" }, [
            _vm._v(_vm._s(_vm.candidateDetailData.realName)),
          ]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm._f("getGenderTextById")(_vm.candidateDetailData.gender)
              )
            ),
          ]),
          _c("span", { staticClass: "text-border" }, [
            _vm._v(
              _vm._s(
                _vm._f("getYearOfExperienceTextById")(
                  _vm.candidateDetailData.yearOfExperience
                )
              )
            ),
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm.candidateDetailData.cityName || "未知城市")),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "resume-info extra" }, [
        _c("span", { staticClass: "resume-text" }, [
          _vm._v(_vm._s(_vm.candidateDetailData.company || "")),
        ]),
        _c("span", { staticClass: "resume-text" }, [
          _vm._v(_vm._s(_vm.candidateDetailData.candidateTitle || "")),
        ]),
      ]),
      _c("p", { staticClass: "resume-info extra" }, [
        _c("span", { staticClass: "resume-text" }, [
          _vm._v(_vm._s(_vm.candidateDetailData.school || "")),
        ]),
        _c("span", { staticClass: "resume-text" }, [
          _vm._v(_vm._s(_vm.candidateDetailData.major || "")),
        ]),
        _c("span", { staticClass: "resume-text" }, [
          _vm._v(
            _vm._s(_vm._f("getDegreeTextById")(_vm.candidateDetailData.degree))
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }