var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "linkedin-resume-detail" },
    [
      _c(
        "div",
        { staticClass: "linkedin-resume-detail-content" },
        [
          _c("base-info", {
            attrs: { candidateDetailData: _vm.candidateDetailData },
          }),
          _c("candidate-resume-detail", {
            ref: "candidateResumeDetail",
            staticClass: "linkedin-resume-detail-card",
            attrs: {
              "is-linkedin": true,
              "candidate-id": _vm.candidateId,
              "is-firm": _vm.candidateDetailData.isFirm,
              "resume-id": _vm.candidateDetailData.tbdResumeId,
            },
          }),
        ],
        1
      ),
      _c("footer-container"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }