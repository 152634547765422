import { render, staticRenderFns } from "./base-info.vue?vue&type=template&id=51db81e1&scoped=true&"
import script from "./base-info.vue?vue&type=script&lang=js&"
export * from "./base-info.vue?vue&type=script&lang=js&"
import style0 from "./base-info.vue?vue&type=style&index=0&id=51db81e1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51db81e1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51db81e1')) {
      api.createRecord('51db81e1', component.options)
    } else {
      api.reload('51db81e1', component.options)
    }
    module.hot.accept("./base-info.vue?vue&type=template&id=51db81e1&scoped=true&", function () {
      api.rerender('51db81e1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/candidate-detail-linkedin/base-info.vue"
export default component.exports