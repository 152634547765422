<template>
    <div class="candidate-base-info">
        <div class="col-16">
            <h3 class="resume-headline">
                <div class="resume-base-info">
                    <span class="resume-name">{{candidateDetailData.realName}}</span>
                    <span>{{candidateDetailData.gender | getGenderTextById}}</span>
                    <span class="text-border">{{candidateDetailData.yearOfExperience | getYearOfExperienceTextById}}</span>
                    <span>{{candidateDetailData.cityName || '未知城市'}}</span>
                    <!-- <a class="linkedin-href" @click="onLinkedinClick"><i class="icon-font icon-social-linkedin"></i></a> -->
                </div>
            </h3>
            <p class="resume-info extra">
                <span class="resume-text">{{candidateDetailData.company || ''}}</span>
                <span class="resume-text">{{candidateDetailData.candidateTitle || ''}}</span>
            </p>
            <p class="resume-info extra">
                <span class="resume-text">{{candidateDetailData.school || ''}}</span>
                <span class="resume-text">{{candidateDetailData.major || ''}}</span>
                <span class="resume-text">{{candidateDetailData.degree | getDegreeTextById}}</span>
            </p>
        </div>
    </div>
</template>

<script>
import degreeInfoData from '#/js/config/degreeKeyValue.json';
import yearOfExperienceData from '#/js/config/yearOfExperienceKeyValue.json';
import genderInfoData from '#/js/config/gender.json';

export default {
    data() {
        return {}
    },
    props: {
        candidateDetailData: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    filters: {
        getDegreeTextById (id) {
            return degreeInfoData[id] || '';
        },
        getYearOfExperienceTextById (id) {
            id = id > 10 ? 10 : id;
            let text = yearOfExperienceData[id] || '未知';
            if(id == 0) {
                text = '未知';
            }
            text += '工作经验';
            return text;
        },
        getGenderTextById (id) {
            return genderInfoData[id] || '';
        }
    },
    methods: {
        onLinkedinClick(){
            if(this.candidateDetailData.linkedInUrl) {
                window.open(this.candidateDetailData.linkedInUrl,'_blank');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '#/css/scss/mixins/_clearfix.scss';
.candidate-base-info {
    @include clearfix();

    margin-bottom: 20px;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    .resume-headline {
        font-size: 0;
        -webkit-text-size-adjust: none;
        color: #333;
        letter-spacing: -.5px;
        clear: both;
        display: inline-block;
        position: relative;
        width: 100%;

        .resume-base-info {
            float: left;

            span{
                display: inline-block;
                font-size: 14px;
                color: #333;
                padding: 0 8px;
                line-height: 16px;
                vertical-align: middle;

                &:first-child{
                    padding-left: 0;
                }

                &.text-border{
                    border-left: 1px solid #d3d3de;
                    border-right: 1px solid #d3d3de;
                }
            }
        }

        .resume-name {
            position: relative;
            display: inline-block;
            font-size: 24px!important;
        }

        .resume-text {
            display: inline-block;
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .linkedin-href{
            display: inline-block;
            cursor: pointer;
            .icon-font{
                color: #007BB5;
                font-size: 15px;
            }
        }
    }

    .resume-info {
        font-size: 14px;
        margin-bottom: 0;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.extra {
            margin-bottom: 4px;

            .fa {
                color: #b9b9b9;
            }

            .fa-mobile {
                font-size: 20px;
                vertical-align: middle;
            }
        }
    }

    .info-right {
        float: right;
    }

    .cloud-icon {
        color: #F98F33;
    }
    .share-list {
        margin: -22px 0 0 -10px;
        li {
            span {
                cursor: pointer;
            }
        }
        .operate-dropmenu {
            top: 36px;
            right: -10px;
        }
    }
}
</style>